* {
    font-family: $font-family-sans-serif;
}

html,
body {
    background-color: $body-bg;
    scroll-behavior: smooth;
}

//Animate default
@-webkit-keyframes AnimationName {
    0% {
        background-position: 39% 0%;
    }
    50% {
        background-position: 62% 100%;
    }
    100% {
        background-position: 39% 0%;
    }
}
@-moz-keyframes AnimationName {
    0% {
        background-position: 39% 0%;
    }
    50% {
        background-position: 62% 100%;
    }
    100% {
        background-position: 39% 0%;
    }
}
@keyframes AnimationName {
    0% {
        background-position: 39% 0%;
    }
    50% {
        background-position: 62% 100%;
    }
    100% {
        background-position: 39% 0%;
    }
}

.animate-default {
    -webkit-animation: AnimationName 15s ease infinite;
    -moz-animation: AnimationName 15s ease infinite;
    animation: AnimationName 15s ease infinite;
}

.whatsapp-mobile {
    position: absolute;
    right: 71px;
    top: 28%;
}

.whatsapp {
    color: $white;
    right: 15px;
    background-color: $green;
    padding: 10px;

    &:hover {
        color: $white;
    }
}

.bg-default {
    background-color: $white;
}

.btn-pink {
    background-color: $pink;
}

.text-pink {
    color: $pink;
}

.text-black {
    color: $black;
}

.transition {
    transition: {
        duration: 0.6s;
        timing-function: ease-in;
        property: all;
    }

    -moz-transition: {
        duration: 0.6s;
        timing-function: ease-in;
        property: all;
    }

    -o-transition: {
        duration: 0.6s;
        timing-function: ease-in;
        property: all;
    }

    -ms-transition: {
        duration: 0.6s;
        timing-function: ease-in;
        property: all;
    }

    -webkit-transition: {
        duration: 0.6s;
        timing-function: ease-in;
        property: all;
    }
}
