header {
    background: #f7f7f7;
    padding: {
        top: 20px;
        bottom: 20px;
    }

    .nav-link.active {
        color: $pink !important;
    }

    @media (max-width: 588px) {
        .navbar-brand {
            z-index: 99999;
        }
    }
}

#sidebar {
    @extend .transition;
    width: 500px;
    background: #f7f7f7;
    position: fixed;
    height: 100%;
    top: 0;
    right: -500px;
    z-index: 9999;

    .btn-whatsapp {
        bottom: 27%;
    }

    &.visible {
        @extend .transition;
        right: 0px;
    }

    .sidebar-btn {
        top: 30px;
        right: 5%;
    }

    .navbar-nav {
        .nav-item {
            .nav-link {
                font-size: 20px;
                padding: 20px;
                color: rgba(0, 0, 0, 0.5);

                &.active {
                    color: $pink;
                }
            }
        }
    }

    .footer {
        bottom: 0;
        &-nav-icons {
            background: $pink;
            padding: 0;

            .nav-link {
                font-size: 15px;
                color: $white;
                padding: 20px !important;
            }
        }
    }

    @media (max-width: 400px) {
        .navbar-nav {
            margin-top: -51px;
            .nav-item .nav-link {
                padding: 10px !important;
            }
        }
        .btn-whatsapp {
            bottom: 19%;
        }
    }

    @media (max-width: 480px) {
        width: 100%;
        right: -100%;
    }
}
