.category {
    &-title,
    &-subtitle {
        font: {
            size: 30px;
            weight: 400;
        }
        color: $black;
    }

    &-subtitle {
        color: $pink;
        font-weight: 800;
    }

    &-link:hover {
        text-decoration: none;
    }

    &-img {
        border-radius: 25px;

        &-title {
            color: $black;
            text-shadow: 0px 0px 2px #00000045;
            text-align: center;
            padding-top: 10px;
            font: {
                size: 20px;
                weight: 500;
            }

            span {
                color: $pink;
                font-weight: 600;
                text-shadow: none;
                text-transform: uppercase;
            }

            @media (min-width: 992px) {
                background: $white;
                margin-top: -85px;
                left: 4%;
                padding: 10px 19px;
                z-index: 1;
                position: absolute;
            }
        }
    }
}
