.slides{
	.owl-nav{
		.owl-prev{
			position: absolute;
			top: 50%;
			left: 0;
		}

		.owl-next{
			position: absolute;
			top: 50%;
			right: 0;
		}
	}
}