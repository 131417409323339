.products {
    .card {
        background: transparent !important;
    }

    &-paginate {
        .page-item {
            padding: 5px;

            &:nth-child(1) {
                .page-link {
                    background: transparent;
                    color: #a09fa4;
                    border: none;
                    font: {
                        size: 20px;
                        weight: bold;
                    }
                }
            }

            &:nth-last-child(-n + 1) {
                .page-link {
                    background: transparent;
                    color: #a09fa4;
                    border: none;
                    font: {
                        size: 20px;
                        weight: bold;
                    }
                }
            }

            .page-link {
                border-radius: 100%;
                background: #a09fa4;
                color: $white;
                width: 37px;
                text-align: center;
            }
            &.active {
                .page-link {
                    background-color: $pink;
                    border: none;
                }
            }
        }
    }

    &-manual {
        ul {
            list-style-image: url("/images/icons/manual.svg");
        }
    }
}
