// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

// Bootstrap
@import "~bootstrap/scss/bootstrap.scss";

//owl
@import "~owl.carousel/dist/assets/owl.carousel.min.css";
@import "~owl.carousel/dist/assets/owl.theme.default.min.css";

// Variables
@import "variables";
@import "base";

//Blocks
@import "category_products";
@import "pages";
@import "contacts";
@import "footer";
@import "products";
@import "header";
@import "slides";
