.contacts {
    padding: {
        top: 3rem;
        bottom: 3rem;
    }

    &-bg {
        background: {
            image: url("/images/min/bg-contacts.png");
            repeat: no-repeat;
            color: #2b2b2b;
            size: contain;
        }
        width: 100%;
        padding: 12%;
        padding-bottom: 4%;
        border-radius: 20px;
        

        @media (max-width: 992px) {
            background: {
                image: url("/images/min/bg-contacts-mobile.png");
                size: contain;
            }
        }
    }

    &-text {
        font: {
            size: 24px;
            weight: 400;
        }
        color: $white;
    }

    .form-control {
        font-size: 12px;
        padding: 21px;
    }

    .btn-pink {
        width: 183px;
        padding: 11px;
        font: {
            size: 12px;
            weight: 200;
        }
        border-radius: 7px;
    }
}
