.footer {
    background-color: $footer-bg;
    &-img {
        width: 200px;
    }

    &-nav {
        background-color: #a09fa4;

        .nav {
            &-item {
                @media (max-width: 992px) {
                    text-align: center;
                    width: 100%;
                }
            }
            .nav-link {
                font-size: 20px;
                color: $white;
            }

            @media (max-width: 992px) {
                display: flex;
                justify-content: center;
                .nav-link {
                    display: grid;
                    justify-content: center;

                    .img-fluid {
                        position: relative;
                        left: 43%;
                        padding-bottom: 1rem;
                    }
                }
            }
        }

        &-icons {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            .nav-item {
                width: auto;
            }
            @media (max-width: 992px) {
                padding-top: 2rem;
                justify-content: center;
            }
        }
    }

    &-maps {
        padding-bottom: 2rem;
        .nav-tabs {
            border: none;
            .nav-link {
                background: #c1c1c1;
                color: $white;
                font-size: 14px;
                border-radius: 0;
                border: none;
                padding: 13px 44px;

                &:nth-child(1) {
                    border-top-left-radius: 24px;
                }

                &:nth-last-child(-n + 1) {
                    border-top-right-radius: 24px;
                }
                &.active {
                    background-color: $pink !important;
                    color: $white;
                }
            }
        }

        .tab-content {
            
            iframe {
                border-top: 4px solid $pink !important;
                border-radius: 5px;
                height: 300px;
                width: 100%;
            }

            #map {
                border-top: 4px solid $pink !important;
                border-radius: 5px;
                height: 300px;
                width: 100%;
            }

            .maps-button {
                max-width: 50%;
                background-color: #e71d57;
                color: #ffffff;
                padding: 13px 30px;
                border-radius: 15px;
                text-align: center;
                margin: 0px auto;
            }
        }
    }

    @media (max-width: 992px) {
        &-img {
            padding-bottom: 2rem;
        }
    }

    @media (min-width: 992px) {
        &-nav {
            padding: {
                top: 1.5rem;
                bottom: 1.5rem;
            }
        }
    }
}

.desktop {
    display: none;
}

@media (hover: hover) and (pointer: fine){
    .desktop {
        display: block;
    }
}

@media (hover: none) and (pointer: coarse){
    .mobile {
        display: block;
    }
}