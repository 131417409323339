.pages {
    background: {
        repeat: no-repeat !important;
        size: cover !important;
        position: center !important;
    }

    .left {
        padding: {
            top: 3rem;
            bottom: 4rem;
        }
    }

    &-content {
        &-title {
            font: {
                size: 50px;
                weight: 400;
            }
        }
        &-subtitle {
            font: {
                size: 25px;
                weight: 600;
            }
        }

        &-content {
            font: {
                size: 16px;
                weight: 400;
            }
            @media (min-width: 992px) {
                width: 85%;
            }
        }

        &-image {
            width: 70%;
        }

        .right {
            .pages-content-title {
                font-weight: 600;
                .text-black {
                    font-weight: 400;
                }
            }
        }

        @media (min-width: 480px) and(max-width: 992px) {
            padding: 1%;
            padding: {
                top: 0;
                bottom: 0;
            }
        }

        @media (max-width: 992px) {
            text-align: center;

            &-title,
            &-subtitle {
                font-size: 30px;
            }

            &-image {
                margin: {
                    top: 20px;
                    bottom: 20px;
                }
            }
        }
    }

    &-internal {
        font: {
            size: 16px;
            weight: 300;
        }
        .pages-content-title {
            span {
                color: $pink;
            }
        }

        a {
            color: $black;
            font-weight: bold;
            text-decoration: none;
        }
        // .padding {
        //     padding: 0rem 3rem 0px 15px;
        // }

        @media (max-width: 767px) {
            text-align: justify;
            .padding {
                padding-top: 4rem;
            }
        }

        @media (min-width: 768px) {
            .padding {
                padding: 0rem 3rem 0px 15px;
            }
        }
    }

    @media (max-width: 992px) {
        margin-top: -45px;
    }
}
